import {
  type UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { Services } from '@/infra/services';
import {
  type Response,
  type Body,
  type Params,
} from '@/infra/services/wishlist/add-item-to-wishlist';
import { type Response as GetWishlistItemsIdsResponse } from '@/infra/services/wishlist/get-wishlist-items-ids';
import { type WishlistError } from '@/infra/services/wishlist/wishlist-error';
import { useGlobalStore } from '@/presenters/store/global';

type Options = UseMutationOptions<Response, WishlistError, Body & Params>;

export const useAddItemToWishlist = (options: Options) => {
  const queryClient = useQueryClient();

  const userId = useGlobalStore((state) => state.user.resources?.id);

  return useMutation<Response, WishlistError, Body & Params>({
    mutationFn: Services.Wishlist.addItemToWishlist,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData<GetWishlistItemsIdsResponse>(
        ['wishlist', userId],
        (prev) => {
          if (!prev) return prev;

          const previousData = prev?.data ?? {
            products: [],
            contents: [],
          };

          return {
            data: {
              ...prev.data,
              [variables.type]: [...previousData[variables.type], variables.id],
            },
          };
        }
      );

      options.onSuccess?.(data, variables, context);
    },
  });
};
