import { type ItemProps } from '../Wishlist';

import { useWishlistItems } from './useWishlistItems';

export function useWishlistItem(item: ItemProps) {
  const { data } = useWishlistItems();

  const isInWishlist =
    item.itemType === 'product'
      ? data?.data.products.includes(item.id)
      : data?.data.contents.includes(item.id);

  return {
    isInWishlist: isInWishlist ?? false,
  };
}
