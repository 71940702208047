import {
  type UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { Services } from '@/infra/services';
import {
  type Response,
  type Body,
} from '@/infra/services/wishlist/create-wishlist';
import { type Response as GetWishlistItemsIdsResponse } from '@/infra/services/wishlist/get-wishlist-items-ids';
import { type WishlistError } from '@/infra/services/wishlist/wishlist-error';
import { useGlobalStore } from '@/presenters/store/global';

import { type ItemProps } from '../Wishlist';

type Options = UseMutationOptions<Response, WishlistError, Body>;

export const useCreateWishlist = (item: ItemProps, options: Options) => {
  const queryClient = useQueryClient();

  const userId = useGlobalStore((state) => state.user.resources?.id);

  return useMutation<Response, WishlistError, Body>({
    mutationFn: Services.Wishlist.createWishlist,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData<GetWishlistItemsIdsResponse>(
        ['wishlist', userId],
        (prev) => {
          if (!prev) return prev;

          const previousData = prev?.data ?? {
            products: [],
            contents: [],
          };

          const type = item.itemType === 'product' ? 'products' : 'contents';

          return {
            data: {
              ...prev.data,
              [type]: [...previousData[type], item.id],
            },
          };
        }
      );

      options.onSuccess?.(data, variables, context);
    },
  });
};
