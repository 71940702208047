import {
  type UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { Services } from '@/infra/services';
import { type Response as GetWishlistItemsIdsResponse } from '@/infra/services/wishlist/get-wishlist-items-ids';
import { type Params } from '@/infra/services/wishlist/remove-item-from-wishlist';
import { type WishlistError } from '@/infra/services/wishlist/wishlist-error';
import { useGlobalStore } from '@/presenters/store/global';

type Options = UseMutationOptions<void, WishlistError, Params>;

export const useRemoveItemFromWishlist = (options: Options) => {
  const queryClient = useQueryClient();

  const userId = useGlobalStore((state) => state.user.resources?.id);

  return useMutation<void, WishlistError, Params>({
    mutationFn: Services.Wishlist.removeItemFromWishlist,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData<GetWishlistItemsIdsResponse>(
        ['wishlist', userId],
        (prev) => {
          if (!prev) return prev;

          if (variables.type === 'contents') {
            return {
              data: {
                ...prev.data,
                contents: prev.data.contents.filter(
                  (item) => item !== variables.id
                ),
              },
            };
          }

          return {
            data: {
              ...prev.data,
              products: prev.data.products.filter(
                (item) => item !== variables.id
              ),
            },
          };
        }
      );

      options.onSuccess?.(data, variables, context);
    },
  });
};
