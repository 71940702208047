import { type MouseEvent } from 'react';

import { Stack, Flex, Text, Badge } from '@backyard-ui/core';

import { type Product } from '@/infra/services/home/queries/modules/index.schemas';
import * as wishlistTracker from '@/infra/tracking/tracker/wishlist/tracker';

import { Image } from '../Image';
import { RatingStars } from '../RatingStars';
import { Wishlist } from '../Wishlist';

import { Price } from './components/Price';
import { Stripe } from './components/Stripe';
import { Tag } from './components/Tag';
import { styles } from './ProductThumb.styles';

export interface ProductThumbProps extends Product {
  onClick?: (event: MouseEvent<HTMLAnchorElement>, product: Product) => void;
  rating: number;
  totalReviewsCount: number;
  isFetchingRatings: boolean;
}

function ProductThumb(props: ProductThumbProps) {
  const { onClick, rating, totalReviewsCount, isFetchingRatings, ...product } =
    props;

  const { id, shortName, tag, price, unit, url, picture, meta } = product;

  const { discount } = price;
  const isOffer = Boolean(discount) && !discount?.featureInstallmentDiscount;

  const classNames = styles({
    offer: isOffer,
    outlet: meta?.isOutlet,
    soldOut: meta?.isSoldOut,
  });

  const linkProps = {
    href: url,
    onClick: (event: MouseEvent<HTMLAnchorElement>) =>
      onClick?.(event, product),
  };

  return (
    <Stack direction="column" spacing={2} className={classNames.base()}>
      <Flex
        align="center"
        justify="space-between"
        className={classNames.meta()}
      >
        <Wishlist
          id={id}
          itemType="product"
          onCreate={(listName) => {
            wishlistTracker.createWishlist(listName);
            wishlistTracker.addProductToWishlist({
              product,
              listName,
            });
          }}
          onAdd={(listName) =>
            wishlistTracker.addProductToWishlist({
              product,
              listName,
            })
          }
          onRemove={() => wishlistTracker.removeProductFromWishlist(product)}
        />

        <Tag type={tag?.type}>{tag?.label}</Tag>
      </Flex>

      <a itemProp="url" className={classNames.picture()} {...linkProps}>
        <Image
          src={picture}
          alt={`Foto de ${shortName}`}
          width={170}
          height={205}
          className="group-hover:scale-105"
          itemProp="image"
        />

        {isOffer && (
          <Stripe
            discount={discount}
            isOutlet={meta.isOutlet}
            className={classNames.stripe()}
          />
        )}
      </a>

      <a className={classNames.info()} {...linkProps}>
        <meta itemProp="brand" content={product.brand} />

        <Stack align="flex-start" direction="column" spacing="2">
          <Text color="gray-900" noOfLines={3} size="md" asChild>
            <span itemProp="shortName">{shortName}</span>
          </Text>

          <Flex align="flex-end" justify="space-between">
            <Stack direction="column" spacing="1">
              <Text color="gray-600" size="sm" asChild>
                <span>
                  Cód. <span itemProp="sku">{id}</span>
                </span>
              </Text>
              <RatingStars
                rating={rating}
                totalReviewsCount={totalReviewsCount}
                isFetchingRatings={isFetchingRatings}
                showTotalReviewsCount
              />
            </Stack>
          </Flex>

          {meta.expressDeliveryDay && (
            <Badge
              appearance="tertiary"
              iconRight={
                /**
                 * NOTE:
                 * ADD TO BACKYARD-ICONS
                 */
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M14.702 6.909c.393.078.75.284 1.014.585a1.361 1.361 0 0 1 .229 1.215 46.191 46.191 0 0 1-.48 1.602c-.165.53-.306.982-.306 1.02a.694.694 0 0 1-.658.485h-1.334a1.91 1.91 0 0 1-3.563 0H7.986a1.92 1.92 0 0 1-3.572 0h-.622a.722.722 0 0 1-.539-.256.74.74 0 0 1-.137-.585L4.523 4.58A1.891 1.891 0 0 1 6.26 2.962h4.34a1.828 1.828 0 0 1 1.654 1.142h.914a1.928 1.928 0 0 1 1.471.685 1.5 1.5 0 0 1 .347 1.206 6.571 6.571 0 0 1-.283.914Zm-8.707 4.713c.067.027.138.04.21.038a.528.528 0 0 0 .539-.539.54.54 0 0 0-.927-.387.548.548 0 0 0-.119.596.54.54 0 0 0 .297.292Zm5.181 0c.067.027.138.04.21.038a.538.538 0 0 0 .548-.539.548.548 0 1 0-.936.385c.051.05.112.09.178.116Zm2-1.186h.832c.183-.63.511-1.69.658-2.083-.028-.092-.293-.201-.805-.201a.676.676 0 0 1-.657-.576.569.569 0 0 0 0-.064V7.44a.303.303 0 0 1 0-.128c.045-.189.125-.422.215-.682l.004-.012a7.82 7.82 0 0 0 .265-.914.52.52 0 0 0-.466-.265h-.914l-.63 2.458a.676.676 0 0 1-.832.493.685.685 0 0 1-.493-.831l.694-2.851a.42.42 0 0 0-.447-.329H6.26c-.202 0-.394.347-.394.54L4.77 9.905a1.892 1.892 0 0 1 1.434-.658 1.91 1.91 0 0 1 1.782 1.188h1.627a1.9 1.9 0 0 1 3.563 0ZM2.87 6.507H1.417a.685.685 0 1 1 0-1.37h1.452a.685.685 0 1 1 0 1.37Zm-.658.301H1.142a.685.685 0 1 0 0 1.371h1.07a.685.685 0 0 0 0-1.37Zm-1.526 1.7H2.13a.685.685 0 0 1 0 1.37H.685a.685.685 0 1 1 0-1.37Z"
                    clipRule="evenodd"
                  />
                </svg>
              }
            >
              {meta.expressDeliveryDay === 'today'
                ? 'Receba hoje'
                : 'Receba amanhã'}
            </Badge>
          )}
        </Stack>
      </a>

      <Price
        meta={meta}
        price={price}
        unit={unit}
        isOffer={isOffer}
        featureInstallmentDiscount={
          discount?.featureInstallmentDiscount ?? false
        }
        className={classNames.price()}
      />
    </Stack>
  );
}

export default ProductThumb;
