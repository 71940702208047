import { useQuery } from '@tanstack/react-query';

import { Services } from '@/infra/services';
import { useGlobalStore } from '@/presenters/store/global';

export function useWishlistItems() {
  const userId = useGlobalStore((state) => state.user.resources?.id);

  return useQuery({
    queryKey: ['wishlist', userId],
    queryFn: Services.Wishlist.getWishlistItemsIds,
    enabled: Boolean(userId),
  });
}
